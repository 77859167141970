<template>
  <v-app-bar
    data-cy="sec_app_bar"
    :color="barColor"
    app
    class="second-app-bar"
    width="100%"
  >
    <v-toolbar-title data-cy="page_title" class="text mr-10">
      {{ appBarTitle }}
    </v-toolbar-title>

    <v-tabs
      v-if="!$route.name.includes('job')"
      align-with-title
      background-color="transparent"
    >
      <v-tabs-slider color="transparent" />
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <!-- structures tab -->
          <v-tab data-cy="tab_structures" v-bind="attrs" v-on="on">
            <v-icon left> mdi-rotate-orbit</v-icon>Structures
          </v-tab>
        </template>
        <v-list>
          <v-list-item
            v-for="item in structuresMenu"
            :key="item"
            :data-cy="item + '_tab'"
            @click="routeToStructuresPage(item, '-structures')"
          >
            <v-list-item-title>{{ item }} Structures</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- create structure tab -->
      <v-tab>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              data-cy="tab_structures_add"
              v-bind="attrs"
              v-on="on"
              fab
              depressed
              outlined
              x-small
              color="hqsOrange"
              class="ml-n13"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in structuresMenu"
              :key="item"
              :data-cy="item + '_create'"
              @click="routeToStructuresPage(item, '')"
            >
              <v-list-item-title>Create {{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-tab>
      <!-- jobs tab -->
      <v-tab
        data-cy="tab_jobs"
        v-if="
          this.$route.name.includes(['dashboard']) ||
          this.$route.name.includes(['result']) ||
          this.$route.name.includes(['application'])
        "
        :to="{ name: 'jobs' }"
      >
        <v-icon left> mdi-chart-donut</v-icon>Jobs
      </v-tab>

      <!-- create job tab -->
      <v-tab
        data-cy="tab_jobs_add"
        v-if="this.$route.name.includes('dashboard')"
      >
        <v-btn
          href="#create-job"
          depressed
          outlined
          fab
          x-small
          color="hqsOrange"
          class="ml-n13"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-tab>

      <!-- apps tab -->
      <v-tab
        data-cy="applications_tab"
        v-if="
          this.$route.path.includes('structures') &&
          !$route.name.includes('all-structures')
        "
        @click.native="routeToStructureDashboard"
      >
        <v-icon left> mdi-rocket</v-icon>Applications
      </v-tab>
    </v-tabs>

    <!-- docs and wiqi tabs -->
    <v-tabs right class="doku-tabs hidden-sm-and-down">
      <v-tabs-slider color="transparent" />
      <v-tab
        :href="
          `http://docs.${subdomain}cloud.quantumsimulations.de/` + docsRouteName
        "
        target="_blank"
      >
        <v-icon left> mdi-lighthouse</v-icon>Documentation
      </v-tab>
    </v-tabs>
  </v-app-bar>
</template>

<script>
import utils from '../../appUtils/utils.js'
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  data() {
    return {
      subdomain: process.env.VUE_APP_SUBDOMAIN,
    }
  },

  props: {
    barColor: String,
    appBarTitle: String,
  },

  methods: {
    ...mapMutations('dynamicForms', [
      'setStructureCard',
      'createStructureCards',
    ]),

    ...mapMutations('backend', ['set']),

    async routeToStructureDashboard() {
      await this.setStructureCard(this.extractLabelFromRouteName)
      let routeName = utils.getStructureDashboardRouteName(this.$route.name)
      this.$router.push({ name: routeName })
    },

    routeToStructuresPage(item, appendage) {
      item = utils.convertToPathNotation(item)
      this.setStructureCard(item)
      this.$router.push({ name: item + appendage })
    },

    async createStructuresDropdown() {
      if (this.$route.name.includes('all')) {
        let structureLabels = await this.schemas
          .filter((e) => this.getAllStructureCardLabels.includes(e.label))
          .map((e) => (e = utils.formatLabel(e.label)))
        await this.set(['structuresMenu', structureLabels])
      } else if (
        this.$route.name.includes('-dashboard') &&
        !this.$route.path.includes('-structure')
      ) {
        const name = utils.stripPathName(this.$route.name)
        let structureLabelsForProduct = this.getStructureLabelsForProduct(name)
        let formattedLabels = []
        for (let label of structureLabelsForProduct) {
          formattedLabels.push(utils.formatLabel(label))
        }
        await this.set(['structuresMenu', formattedLabels])
      } else {
        let structureLabel = utils.formatLabel(this.extractLabelFromRouteName)
        await this.set(['structuresMenu', [structureLabel]])
      }
    },
  },

  computed: {
    ...mapState('backend', {
      currentInputStructureSchemas: (state) =>
        state.currentInputStructureSchemas,
      currentWorkflows: (state) => state.currentWorkflows,
      structuresMenu: (state) => state.structuresMenu,
      docsRouteName: (state) => state.docsRouteName,
      schemas: (state) => state.schemas,
    }),

    ...mapState('dynamicForms', {
      currentStructureCard: (state) => state.currentStructureCard,
    }),

    ...mapGetters('backend', [
      'getSchemasByTag',
      'getStructureLabelsForProduct',
    ]),

    ...mapGetters('dynamicForms', ['getAllStructureCardLabels']),

    extractLabelFromRouteName() {
      let label = utils.stripPathName(this.$route.name)
      return utils.convertToUnderscoreNotation(label)
    },
  },

  async created() {
    if (!this.$route.path.includes('application')) {
      this.set(['docsRouteName', ''])
    }
    await this.createStructureCards(this.getSchemasByTag('structure'))
    this.createStructuresDropdown()
  },
}
</script>

<style scoped>
.second-app-bar {
  position: fixed;
  margin-top: 65px !important;
}

.v-toolbar__title {
  overflow: visible;
  margin-left: 10px;
  width: 300px !important;
}

.doku-tabs {
  padding-right: 10px;
}
</style>
