var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"second-app-bar",attrs:{"data-cy":"sec_app_bar","color":_vm.barColor,"app":"","width":"100%"}},[_c('v-toolbar-title',{staticClass:"text mr-10",attrs:{"data-cy":"page_title"}},[_vm._v(" "+_vm._s(_vm.appBarTitle)+" ")]),(!_vm.$route.name.includes('job'))?_c('v-tabs',{attrs:{"align-with-title":"","background-color":"transparent"}},[_c('v-tabs-slider',{attrs:{"color":"transparent"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({attrs:{"data-cy":"tab_structures"}},'v-tab',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-rotate-orbit")]),_vm._v("Structures ")],1)]}}],null,false,1393839396)},[_c('v-list',_vm._l((_vm.structuresMenu),function(item){return _c('v-list-item',{key:item,attrs:{"data-cy":item + '_tab'},on:{"click":function($event){return _vm.routeToStructuresPage(item, '-structures')}}},[_c('v-list-item-title',[_vm._v(_vm._s(item)+" Structures")])],1)}),1)],1),_c('v-tab',[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-n13",attrs:{"data-cy":"tab_structures_add","fab":"","depressed":"","outlined":"","x-small":"","color":"hqsOrange"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,240555518)},[_c('v-list',_vm._l((_vm.structuresMenu),function(item){return _c('v-list-item',{key:item,attrs:{"data-cy":item + '_create'},on:{"click":function($event){return _vm.routeToStructuresPage(item, '')}}},[_c('v-list-item-title',[_vm._v("Create "+_vm._s(item))])],1)}),1)],1)],1),(
        this.$route.name.includes(['dashboard']) ||
        this.$route.name.includes(['result']) ||
        this.$route.name.includes(['application'])
      )?_c('v-tab',{attrs:{"data-cy":"tab_jobs","to":{ name: 'jobs' }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-chart-donut")]),_vm._v("Jobs ")],1):_vm._e(),(this.$route.name.includes('dashboard'))?_c('v-tab',{attrs:{"data-cy":"tab_jobs_add"}},[_c('v-btn',{staticClass:"ml-n13",attrs:{"href":"#create-job","depressed":"","outlined":"","fab":"","x-small":"","color":"hqsOrange"}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1):_vm._e(),(
        this.$route.path.includes('structures') &&
        !_vm.$route.name.includes('all-structures')
      )?_c('v-tab',{attrs:{"data-cy":"applications_tab"},nativeOn:{"click":function($event){return _vm.routeToStructureDashboard.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-rocket")]),_vm._v("Applications ")],1):_vm._e()],1):_vm._e(),_c('v-tabs',{staticClass:"doku-tabs hidden-sm-and-down",attrs:{"right":""}},[_c('v-tabs-slider',{attrs:{"color":"transparent"}}),_c('v-tab',{attrs:{"href":"http://docs." + _vm.subdomain + "cloud.quantumsimulations.de/" + _vm.docsRouteName,"target":"_blank"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-lighthouse")]),_vm._v("Documentation ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }